module.exports = function() {
    $('#slider-1').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '#slider__prev-1',
        nextArrow: '#slider__next-1',
        dots: true,
        appendDots: '#slider-1-dots'
    })

    $('#slider-2').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '#slider__prev-2',
        nextArrow: '#slider__next-2',
        dots: true,
        appendDots: '#slider-2-dots'
    })

    $('#slider-3').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '#slider__prev-3',
        nextArrow: '#slider__next-3',
        dots: true,
        appendDots: '#slider-3-dots'
    })
}